import {
  CountryCode,
  DetectionStatus,
  InsightItem,
  MedicalInsight,
  MedicalResult,
  MedicationProductCodeKey,
  Question,
  ResultType,
  SelectedBox,
  ShippingAddress,
  SubscriptionPlan,
  TreatmentStatus
} from '@dayetopia/types';
import { BooleanField, ColorField, NumberField, RTNode, SelectField, KeyTextField } from '@prismicio/types';
import { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { I18NextContext } from 'gatsby-plugin-react-i18next/dist/types';
import { RichTextBlock } from 'prismic-reactjs';
import {
  BooleanTextField,
  HtmlField,
  ImageField,
  PlainTextField,
  RichTextField,
  TextField,
  VideoField,
  VideoResource,
  WebResource
} from '@components/shared/vms/CommonTypes';

export interface SEOProps {
  uid?: string;
  href?: string;
  description?: string;
  image?: string;
  title?: string;
  productData?: any;
  articleData?: any;
  preloadImage?: string;
}

export interface ContentRelationshipFieldPrismic {
  uid: string;
}

export interface Category {
  node: CategoryNode;
}

export interface CategoryNode {
  uid: string;
  id: string;
  data: {
    category_image: {
      url: string;
    };
    meta_title: string | null;
    meta_description: string | null;
    category_color: string;
    tag_name_color: string;
    image_background_color: string;
    font_color: string;
    title: string;
    category_description: string;
  };
}

export interface ArticleCategory {
  id: string;
  uid?: string;
  slug: string;
  data?: {
    title: string;
    category_color: string;
  };
}

export enum ArticleShoppingBannerMobileViewDirection {
  HORIZONTAL = 'horizontal (wide)',
  VERTICAL = 'vertical (tall)'
}

export interface ArticleShoppingBanner {
  banner: {
    uid: string;
  };
  sb_mobile_view: SelectField<ArticleShoppingBannerMobileViewDirection>;
}

export interface ShoppingBanner {
  uid: string;
  title?: TextField;
  text?: RichTextField;
  button_text?: KeyTextField;
  button_link?: KeyTextField;
  country?: KeyTextField;
  main_image_horizontal?: ImageField;
  main_image_vertical?: ImageField;
  background_color?: KeyTextField;
  background_image_horizontal?: ImageField;
  background_image_vertical?: ImageField;
}

export type Article<T extends ArticleData | BrandedArticleData = ArticleData> = T extends BrandedArticleData
  ? {
      id: string;
      data: T;
    }
  : {
      alternate_languages?: any[];
      data: ArticleData;
      first_publication_date: string;
      href?: string;
      id?: string;
      lang?: string;
      last_publication_date?: string;
      linked_documents?: any[];
      slugs?: string[];
      tags?: any[];
      type?: string;
      uid: string;
      url: string;
    };

export interface ArticleData {
  title: string;
  article_subheadline: string;
  survey_title?: any;
  poll?: { link_type: string; id: string };
  meta_title: string;
  meta_image: any;
  meta_description: string | null;
  image_layout?: string;
  hide_in_us?: boolean;
  hide_in_index?: boolean | null;
  hero_credits: any;
  featured_image: any;
  category: ArticleCategory;
  body: {
    id: string;
    slice_type: string;
    primary: any;
  }[];
  author: any;
  authors: ArticleAuthor[];
  illustrators: ArticleIllustrator[];
  medical_reviewers: ArticleMedReviewer[];
  audio: any;
  article_tags?: any[];
  article_read_time?: number;
  navigation_image?: ImageField;
  navigation_text?: string;
  navigation_badge_color?: string;
  branded_url?: string;
}

export interface RichTextContent {
  spans: {
    end: number;
    start: number;
    type: string;
  }[];
  text: string;
  type: string;
}

export interface BrandedArticleData {
  featured_image: {
    url: string;
  };
  title: string;
  branded_url: string;
}

export enum ArticleSliceType {
  RichText = 'rich_text',
  Quote = 'quote',
  SingleImage = 'single_image',
  ShoppingBanner = 'shopping_banner',
  ProductCards = 'product_cards',
  VideoEmbed = 'video_embed',
  Newsletter = 'newsletter',
  CaseStudies = 'case_study_section',
  VMSFoundersResults = 'vms_founders_results',
  // @deprecated - will be deleted
  ShoppingModule = 'shopping_module',
  HorizontalRule = 'horizontal_rule'
}

export interface ArticleAuthor {
  article_author: ContentRelationshipFieldPrismic;
}

export interface ArticleIllustrator {
  illustrator: ContentRelationshipFieldPrismic;
}

export interface ArticleMedReviewer {
  medical_reviewer: ContentRelationshipFieldPrismic;
}

export interface Authors {
  nodes: VitalsAuthorNode[];
}

export interface VitalsAuthorNode {
  id: string;
  uid: string;
  url: string;
  data: VitalsAuthorData;
}

export interface VitalsAuthorData {
  name: KeyTextField;
  professional_title: KeyTextField;
  author_bio: RichTextField;
  image: {
    alt?: string;
    url: string;
    gatsbyImageData: IGatsbyImageData;
  };
  tags: AuthorTag[];
  can_book_consultations: BooleanField;
  meta_description?: string;
  meta_image?: {
    url: string;
  };
  meta_title?: string;
}

export interface AuthorTag {
  tag: SelectField<VitalsAuthorTags>;
}

enum VitalsAuthorTags {
  Contraception = 'Contraception',
  Menopause = 'Menopause',
  Fertility = 'Fertility',
  IVF = 'IVF',
  DonorConception = 'Donor conception',
  PCOS = 'PCOS',
  Constipation = 'Constipation',
  BackPain = 'Back pain',
  BV = 'BV',
  Thrush = 'Thrush',
  Miscarriage = 'Miscarriage',
  Endometriosis = 'Endometriosis',
  SexuallyTransmittedInfections = 'Sexually transmitted infections',
  RecurrentInfections = 'Recurrent infections',
  PelvicPain = 'Pelvic pain',
  PainDuringSex = 'Pain during sex',
  UrinaryUrgencyFreq = 'Urinary urgency/frequency',
  HormonalHealth = 'Hormonal health',
  ReproductiveHealth = 'Reproductive health',
  GutHealth = 'Gut health'
}

export interface ProductData {
  id: string;
  title: string;
  subtitle?: string;
  description?: string;
  descriptionUS?: string;
  category?: string;
  color?: string;
  url?: string;
  urlUS?: string;
  showcaseImage?: string;
  showcaseImageMobile?: string;
  country_sorting?: { text: string };
  includes?: string[];
}

export interface BoxState {
  boxSize: number;
  cbdregular: number;
  cbdsuper: number;
  nakedregular: number;
  nakedsuper: number;
}

export enum PadSizeType {
  LINER = 'pl',
  REGULAR = 'pr',
  SUPER = 'pn',
  SUPER_PLUS = 'pnp'
}

export interface PadsBoxState {
  boxSize: number;
  [PadSizeType.LINER]: number;
  [PadSizeType.REGULAR]: number;
  [PadSizeType.SUPER]: number;
  [PadSizeType.SUPER_PLUS]: number;
}

export interface PadsBoxStackState {
  [PadSizeType.LINER]: number;
  [PadSizeType.REGULAR]: number;
  [PadSizeType.SUPER]: number;
  [PadSizeType.SUPER_PLUS]: number;
}

export const default12TamponConfiguration = {
  boxSize: 12,
  cbdregular: 0,
  cbdsuper: 6,
  nakedregular: 3,
  nakedsuper: 3
};

export const default18TamponConfiguration = {
  boxSize: 18,
  cbdregular: 9,
  cbdsuper: 0,
  nakedregular: 9,
  nakedsuper: 0
};

export const defaultPadsConfiguration: PadsBoxState = {
  boxSize: 16,
  [PadSizeType.LINER]: 0,
  [PadSizeType.REGULAR]: 0,
  [PadSizeType.SUPER]: 16,
  [PadSizeType.SUPER_PLUS]: 0
};

export const defaultPadsStackConfiguration: PadsBoxStackState = {
  [PadSizeType.LINER]: 0,
  [PadSizeType.REGULAR]: 1,
  [PadSizeType.SUPER]: 0,
  [PadSizeType.SUPER_PLUS]: 0
};

export enum NavBarCategory {
  Shop = 'shop',
  AboutUs = 'aboutUs',
  VaginalHealth = 'vaginalHealth',
  Discover = 'discover',
  Search = 'search',
  Accessories = 'accessories'
}

export enum NavStatus {
  Menu = 'menu',
  Basket = 'basket',
  Default = 'default'
}

export enum ScreeningType {
  Vms = 'vms',
  Sti = 'sti',
  Hpv = 'hpv'
}

export type Prices = { [key: string]: number };

export interface ProductPriceData {
  amount: number;
  currency: string;
  flexPriceId?: string;
}

export interface ProductPrice {
  id: string;
  isHsaFsaEligible: boolean;
  name: string;
  prices: ProductPriceData[];
  vat: Prices;
}

interface SEOPricePerPlan {
  planType: string;
  price: ProductPriceData[];
}

export interface SEOPriceData {
  pricePerPlan?: SEOPricePerPlan[];
  price: ProductPriceData;
  vat?: { [key: string]: number };
  name: string;
  id__normalized: string;
}

export interface Offer {
  '@type': 'Offer';
  sku: string;
  name: string;
  price: string; // Assuming price is formatted as a string with two decimal places
  priceCurrency: string;
  itemCondition: string;
  availability: string;
}

export type NavStatusKey = NavStatus;

export enum ProductCode {
  TamponBox = 'tampon_box',
  Proviotics = 'proviotics',
  ProvioticsMultiMonth = 'proviotics_mm',
  CbdBalm = 'cbd_balm',
  Sampler = 'sampler',
  HolidaySampler = 'holiday_sampler',
  Masks = 'masks',
  MaskPink = 'mask_pink',
  MaskBlue = 'mask_blue',
  MaskLight = 'mask_light',
  MaskDark = 'mask_dark',
  VaginaMattersBook = 'vagina_matters_book',
  GiftCard = 'gift_card',
  BlmPin = 'blm_pin',
  USWaitlistPrepaid12 = 'us_waitlist_prepaid_12',
  USWaitlistPrepaid18 = 'us_waitlist_prepaid_18',
  ValentinesSampler = 'valentines_sampler',
  ValentinesBox = 'valentines_box',
  TamponPouch = 'tampon_pouch',
  TamponTin = 'tin_case',
  ScreeningKit = 'screening_kit',
  STIScreening = 'sti_screening',
  HPVScreening = 'hpv_screening',
  HPVAdvanced = 'hpv_advanced',
  Pads = 'pads_box',
  RoseCandle = 'rose_candle',
  PPCStandard = 'ppc_standard',
  PPCAdvanced = 'ppc_advanced',
  PPCPremium = 'ppc_premium',
  VMSEssential = 'vms_essential',
  VMSAdvanced = 'vms_advanced',
  VMSPremium = 'vms_premium',
  VMSEssentialConsultation = 'vms_essential_consultation_basic',
  VMSEssentialReplacement = 'vms_essential_replacement',
  VMSMedicationFee = 'vms_medication_fee',
  VMSPrescriptionFee = 'vms_prescription_fee',
  STIPrescriptionFee = 'sti_prescription_fee',
  PPCTamponBox = 'ppc_tampon_box',
  ConsultationSexualHealth = 'consultation_sexual_health',
  ConsultationFertility = 'consultation_fertility',
  ConsultationGeneralPractitioner = 'consultation_general_practitioner',
  ConsultationNutritionist = 'consultation_nutritionist',
  ConsultationPelvicFloorTherapist = 'consultation_pelvic_floor_therapist',
  FestiveBundle = 'festive_bundle',
  VaginalHealthBundle = 'proviotics_vaginal_health',
  PeriodCareBundle = 'tampon_box_bundle_all_in_one',
  PadsBox12L = 'pads_box_12l',
  PadsBox24L = 'pads_box_24l',
  PadsBox12R = 'pads_box_12_regular',
  PadsBox12S = 'pads_box_12_super',
  PadsBox10SP = 'pads_box_10_super_plus',
  PadsBox20R = 'pads_box_20r',
  PadsBox16SP = 'pads_box_16sp',
  TamponBoxUS = 'tampon_box_18nr',
  TamponBoxTrialUS = 'tampon_box_4cr5cs5nr4ns'
}

export type ProductCodeKey = ProductCode;

export enum CartItemCode {
  Shipping = 'shipping',
  Discount = 'discount',
  ValentinesSampler = 'valentines_sampler',
  ValentinesBox = 'valentines_box'
}

export enum CartItemGiftType {
  Promotion = 'promotion',
  PromotionCode = 'promotion-code'
}

export type CartItemCodeKey = CartItemCode;

export interface BoxItem {
  name: string;
  count: number;
  key?: string;
  price?: number;
}

export class CartItem {
  constructor(
    public id: string,
    public productCode: ProductCode,
    public price: number,
    public quantity: number = 1,
    public type: CartItemType = CartItemType.OneOffPurchase,
    public editable: boolean = true,
    public giftType?: CartItemGiftType,
    public originalPrice?: number,
    public unique?: boolean,
    public originalShippingFee?: number,
    public shippingFee?: number,
    public dependantCartItems?: CartItem[],
    public boxItems?: BoxItem[]
  ) {}
}

export interface GiftCardCartItem extends CartItem {
  details: GiftCardDetails;
}

export interface GiftCardDetails {
  recipient: {
    name: string;
    email: string;
  };
  sender: {
    name: string;
  };
  message: string;
}

export interface SliceLinkCarouselDetails {
  background: TextField;
  background_image: ImageField;
}

export interface SubscriptionCartItem extends CartItem {
  subscriptionType: SubscriptionType;
  subscriptionInterval: Interval;
  billing: { installments: number };
  delivery: { installments: number };
  selectedPlan: SubscriptionPlan;
  subscriptionPlans: SubscriptionPlan[];
}

export enum SubscriptionType {
  Tampons = 'tampons',
  Proviotics = 'proviotics',
  Pads = 'pads'
}

export interface Interval {
  unit: IntervalUnit;
  length: number;
}

export enum IntervalUnit {
  Days = 'days',
  Months = 'months'
}

export type CartItems = CartItem[];

export interface CurrencyObj {
  letterCode: string;
  symbol: string;
}

export type ShippingFees = {
  [key in ProductCodeKey]: { shippingPrice: number; originalShippingPrice: number };
};

export interface ShippingObject {
  countryCode: string;
  country: string;
  currencyCode: string;
  currencySymbol: string;
  shippingFees: any;
  freeShippingRequirement?: number;
}
type ShippingObjects = ShippingObject[];
export interface PromotionObject {
  startDate: string;
  endDate: string;
  freeGift?: string;
  countries: string[];
  planCodes?: string[];
  productCodes?: string[];
  spendRequirement?: {
    [key: string]: number;
  };
  couponCode?: string;
  description?: string;
}
type PromotionObjects = PromotionObject[];

export interface EcommerceDataObj {
  promotions: PromotionObjects;
  shippingFees: ShippingObjects;
}
export interface PageSlice {
  id?: string;
  slice_type: string;
  primary?: PageSliceDetails;
  items?: PageSliceItem[];
}

export enum PageSliceType {
  Text = 'text',
  Grid = 'grid',
  FlexibleGrid = 'flexible_grid',
  BannerGrid = 'banner_grid',
  LinkTabs = 'link_tabs',
  InnerButtonSlider = 'inner_button_slider',
  ProductCompareCards = 'product_compare_cards',
  Timeline = 'timeline',
  PathogenList = 'pathogen_list',
  HeroBanner = 'hero_banner',
  Image = 'image',
  Trustpilot = 'trustpilot',
  ComparisonTable = 'comparison_table',
  FeaturedProducts = 'featured_products',
  FeaturedIn = 'featured_in',
  VitalsCarousel = 'vitals_carousel',
  Spacer = 'spacer',
  ImageText = 'image_text',
  ImageGrid = 'image_grid',
  IconTextGrid = 'icon_text_grid',
  SubscriptionModule = 'subscription_module',
  TestimonialsSlider = 'testimonials_slider',
  ScienceModule = 'science_module',
  FAQs = 'faqs',
  TamponsModalRow = 'tampons_modal_row',
  Video = 'video',
  Tour = 'tour',
  TextFeatureRow = 'text_feature_row',
  Endorsements = 'endorsements_row',
  ZeroWaste = 'zero_waste',
  GiftCardForm = 'gift_card_form',
  ButtonRow = 'button_row',
  IndentifierRow = 'identifier_row',
  CaseStudySection = 'case_study_section',
  ImageSlider = 'image_slider',
  TwoColumnGrid = 'two_column_grid',
  Card = 'card',
  Certificates = 'certificates',
  VMSSpecialistCare = 'vms_-_specialist_care',
  VMSSpecialistCarePrices = 'vms_-_specialist_care_prices',
  VMSFoundersResults = 'vms_-_founder_s_results',
  VMSVaginalFlora = 'vms_-_vaginal_flora',
  VMSMicrobiomeVideo = 'vms_-_microbiome_video',
  VitalsSection = 'vitals_section',
  Newsletter = 'newsletter',
  ImageTextBulletList = 'image_text_bullet_list',
  VideoGrid = 'video_grid',
  HeroSlider = 'hero_slider',
  HeaderImage = 'header_image',
  HeaderWaitlist = 'header_waitlist',
  OverlappingItems = 'overlapping_items',
  PPCPackages = 'ppc_packages',
  PPCDidYouKnow = 'ppc_did_you_know',
  PriorityTips = 'priority_tips',
  ContentCarousel = 'content_carousel',
  LinkCarousel = 'link_carousel',
  VmsAtHome = 'vms_at_home',
  TwoColumnImageAndText = 'two_column_image_and_text',
  IconDescriptionBoxes = 'icon_description_boxes',
  HowItWorksSteps = 'how_it_works_steps',
  PositivesAndImage = 'positives_and_image',
  ImageAndInfo = 'image_and_info',
  VideoHalfRow = 'video_half_row',
  ProductComparisonTable = 'product_comparison_table',
  ScreeningParametersInfoSlider = 'screening_parameters_info_slider',
  StepSlider = 'step_slider',
  STIFoundersResults = 'sti_founders_results',
  HPVFoundersResults = 'hpv_founders_results',
  ConsultationsExplained = 'consultations_explained',
  ConsultationsUpsell = 'consultations_upsell',
  ConsultationsMeetNurses = 'consultations_meet_nurses',
  ProductUpsellRow = 'product_upsell_row',
  BenefitsTable = 'comparison_table1',
  HeadingMessage = 'heading_message',
  TextImageCarouselAnimated = 'text_image_carousel_animated',
  ThreeStatistics = 'three_statistics',
  TwoColProducts = 'two_col_products',
  SlideAnimation = 'slide_animation',
  CustomSlice = 'custom_slice',
  FrontrowWidget = 'frontrow_widget',
  FireworkWidget = 'firework',
  ImageAndContent = 'image_and_content',
  HeroFullWidthImage = 'hero_full_width_image',
  TabsFeature = 'tabs_feature',
  CarouselModule = 'carousel_module',
  DonationHero = 'donation_hero'
}

export type PageSliceTypeKey = PageSliceType;

interface SliceGeneralDetails {
  background_color?: string;
  text_color?: string;
  text_align?: string;
  hide?: boolean;
}

interface SliceSpacerDetails {
  height: {
    text: string;
  };
  hide_in_countries?: {
    text: string;
  };
}

export interface SliceTextDetails {
  background_color: string;
  background_gradient: TextField;
  padding: TextField;
  mobile_padding: TextField;
  maxwidth: TextField;
  text: RichTextField;
  text_align: string;
  text_align_mobile: string;
  text_color: string;
  font_size: string;
  font_size_mobile: string;
}

export interface SliceVideoDetails {
  background_color?: string;
  mobile_video?: VideoResource;
  video: VideoResource;
  cover: boolean;
  height: TextField;
  width: TextField;
  mobile_height: TextField;
  mobile_width: TextField;
}

export interface SliceImageTextDetails {
  klaviyo_id?: TextField;
  text_color: PlainTextField;
  text_align: PlainTextField;
  alignment: PlainTextField;
  padding: TextField;
  mobile_padding: TextField;
  mobile_reverse: BooleanField;
  background_color: PlainTextField;
  text: HtmlField;
  image?: ImageField;
  video?: WebResource;
  layout: PlainTextField;
  gap: TextField;
  mobile_gap: TextField;
  icon_gap: TextField;
  breakpoint: TextField;
  background_gradient: TextField;
  image_width: TextField;
  mobile_image_width: TextField;
  text_width: TextField;
  border: TextField;
  mobile_text_align: string;
}

export interface SliceVmsAtHomeDetails {
  at_home_subtitle: TextField;
  at_home_text: TextField;
  at_home_disclaimer: TextField;
  at_home_image: WebResource;
  at_home_button_link: WebResource;
  at_home_button: TextField;
  at_home_background: TextField;
  at_home_padding: TextField;
  at_home_mobile_padding: TextField;
}

export interface SliceTextFeatureDetails {
  title: { text: string };
  text: { text: string };
  text_color: string;
}

export interface SliceIconTextGridDetails {
  gap: TextField;
  gap_mobile: TextField;
  icon_gap: TextField;
  icon_gap_mobile: TextField;
  padding: TextField;
  mobile_padding: TextField;
  alignment_desktop: PlainTextField;
  alignment_mobile: PlainTextField;
  background_color: PlainTextField;
  background_gradient: TextField;
  column_max_width: TextField;
  columns: TextField;
  columns_mobile: TextField;
  grid_title: TextField;
  grid_title_rich: RichTextField;
  is_title_small: BooleanTextField;
  image_height: TextField;
  image_width: TextField;
  text_color: PlainTextField;
  expandable: BooleanField;
  expandable_text: TextField;
  max_visible_items: TextField;
  button_title: TextField;
  button_link: TextField;
  inner_width: TextField;
  inner_background: TextField;
  inner_padding: TextField;
  inner_padding_mobile: TextField;
  is_inner_bordered: BooleanField;
  is_inner_top_border_rounded: BooleanField;
  is_inner_bottom_border_rounded: BooleanField;
  inner_max_width: TextField;
  inner_max_width_mobile: TextField;
  is_small_title: BooleanField;
  is_small_item_title: BooleanField;
  scroll_on_mobile: BooleanField;
}

export interface SliceHeaderImageDetails {
  button_text: TextField;
  button_link: TextField;
  background: TextField;
  subtitle: TextField;
  heading: TextField;
  info: TextField;
  image: ImageField;
  mobile_image: ImageField;
  is_image_outside: BooleanField;
  waitlist_outside_uk: BooleanField;
  waitlist_us: TextField;
  waitlist_eu: TextField;
  icon_1_text: TextField;
  icon_2_text: TextField;
  icon_3_text: TextField;
  icon_1?: ImageField;
  icon_2?: ImageField;
  icon_3?: ImageField;
  coming_soon?: boolean;
}

export interface SliceHeaderWaitlistDetails {
  button_text: TextField;
  button_link: TextField;
  background: TextField;
  subtitle: TextField;
  heading: TextField;
  info: TextField;
  image: ImageField;
  mobile_image: ImageField;
  is_image_outside: BooleanField;
  waitlist_id: string;
  icon_1_text: TextField;
  icon_2_text: TextField;
  icon_3_text: TextField;
  icon_1?: ImageField;
  icon_2?: ImageField;
  icon_3?: ImageField;
  coming_soon?: boolean;
}

export interface SliceNewsletterDetails {
  type: 'cbd-enfused' | 'period-pain' | 'period-pain-waitlist' | 'hpv-waitlist';
}

export interface SlicePriorityTipsDetails {
  heading: TextField;
  padding: TextField;
  background: TextField;
  bottom_info: TextField;
  bottom_info_image: ImageField;
}

export interface SliceOverlappingItemsDetails {
  heading: TextField;
  padding: TextField;
  background: TextField;
}

export interface SliceTwoColumnImageAndTextDetails {
  main_text_key: string;
  secondary_text_key: string;
  padding_style: string;
  background_style: string;
  image?: ImageField;
}

export interface SliceIconDescriptionBoxesDetails {
  heading_text_key: string;
  padding_style: string;
  background_style: string;
  heading_icon: ImageField;
  column_max_width: TextField;
  icon_max_width?: string;
  mobile_icon_max_width?: string;
  main_text_font_size?: string;
  mobile_main_text_font_size?: string;
  is_mobile_slider?: boolean;
}

export interface SliceHeroSliderItem {
  image_mobile_max_width?: string;
  image_mobile_padding?: string;
  info: TextField;
  icon_1_text: TextField;
  icon_2_text: TextField;
  icon_3_text: TextField;
  icon_1?: ImageField;
  icon_2?: ImageField;
  icon_3?: ImageField;
  background: TextField;
  show_in_countries: TextField;
  aux_image_mobile?: boolean;
  title_max_width?: string;
  title_mobile_max_width?: string;
  info_max_width?: string;
  info_mobile_max_width?: string;
  icons_background?: string;
  aux_image_max_width?: string;
  aux_image_mobile_max_width?: string;
  priorityNum?: number;
  title: RichTextField;
  subtitle: TextField;
  background_image: ImageField;
  image: ImageField;
  mobile_image: ImageField;
  button_1_text: TextField;
  button_1_link: TextField;
  product_code: TextField;
  hide_button_2?: boolean;
  hide_mobile_image: boolean;
  mobile_reverse: boolean;
  image_padding: boolean;
  aux_image?: ImageField;
  button_color?: string;
  no_aux_image?: boolean;
  coming_soon?: boolean;
  desktop_text_align_left?: boolean;
  design_style?: string;
}

export interface SliceHowItWorksStepsDetails {
  heading_text_key: string;
}

export interface SliceImageAndInfoDetails {
  aux_image?: ImageField;
  top_heading_text_key?: string;
  top_heading_badge_text_key?: string;
  heading_text_key?: string;
  heading_font_size?: string;
  heading_font_size_mobile?: string;
  focus_text_key?: string;
  focus_text_description_key?: string;
  mobile_align_center?: boolean;
  show_link_instead_of_button?: boolean;
  description_text_key?: string;
  button_text_key?: string;
  background_style?: string;
  mobile_background_style?: string;
  image?: ImageField;
  link?: string;
  desktop_padding?: string;
  layout?: string;
  mobile_layout?: string;
}

export interface SliceVideoHalfRowDetails {
  background_css?: TextField;
  mobile_background_css?: TextField;
  text_color?: string;
  mobile_text_color?: string;
  heading?: TextField;
  subtitle?: TextField;
  desktop_video?: VideoResource;
  mobile_video?: VideoResource;
  desktop_placeholder_image?: ImageField;
  mobile_placeholder_image?: ImageField;
  button_text?: TextField;
  button_link?: TextField;
  text_info_type?: VideoHalfRowDetailsInfoType | null;
  mobile_info_covers_video?: boolean;
}

export enum VideoHalfRowDetailsInfoType {
  PadsInfo = 'pads-info'
}

export interface SliceScreeningParametersInfoSliderDetails {
  type: 'sti' | 'sti-landing';
}

export interface SlicePositivesAndImageDetails {
  heading_text_key: string;
  image?: ImageField;
  background_style: string;
  mobile_background_style: string;
  button_href: string;
  button_text_key: string;
}

export interface PageSliceComparisonTable {
  price_optibac: { text: string };
  price_seed: { text: string };
  price_garden_of_life: { text: string };
}

export interface SliceVmsMicrobiomeVideo {
  microbiome_title: TextField;
  microbiome_text: TextField;
  microbiome_more: TextField;
  youtube_video_id?: TextField;
  microbiome_video?: WebResource;
  microbiome_video_height?: PlainTextField;
  microbiome_video_width?: PlainTextField;
  microbiome_image: WebResource;
}

export interface SliceVitalsCarouselDetails {
  article_ids_string: string;
  heading: TextField;
  hide_title: boolean;
  show_read_more_links: boolean;
  background: TextField;
}

export interface SliceTimelineDetails {
  overline?: PlainTextField;
  heading?: RichTextField;
  subheading?: TextField;
}

export interface SlicePathogenListDetails {
  overline?: PlainTextField;
  heading?: RichTextField;
  subheading?: TextField;
  image: ImageField;
  screening_type?: ScreeningType;
}

export interface SliceCaseStudySectionDetails {
  section_title: RichTextField;
  color?: ColorField;
  background_color?: ColorField;
  padding?: TextField;
  rowGap?: TextField;
}

export type PageSliceDetails =
  | SliceGeneralDetails
  | SliceSpacerDetails
  | SliceTextDetails
  | SliceProductCompareCardsDetails
  | SliceInnerButtonSliderDetails
  | SliceTimelineDetails
  | SlicePathogenListDetails
  | SliceHeroBannerDetails
  | SliceImageTextDetails
  | SliceVideoDetails
  | SliceTextFeatureDetails
  | SliceIconTextGridDetails
  | SliceNewsletterDetails
  | SlicePriorityTipsDetails
  | SliceOverlappingItemsDetails
  | SliceVmsAtHomeDetails
  | PageSliceComparisonTable
  | SliceHeaderImageDetails
  | SliceTwoColumnImageAndTextDetails
  | SliceLinkCarouselDetails
  | SliceIconDescriptionBoxesDetails
  | SliceHowItWorksStepsDetails
  | SliceImageAndInfoDetails
  | SliceScreeningParametersInfoSliderDetails
  | SlicePositivesAndImageDetails
  | SliceVitalsCarouselDetails
  | SliceVmsMicrobiomeVideo
  | SliceBenefitsTable
  | SliceHeadingMessage
  | SliceHeroSlice
  | SliceTextImageCarouselAnimated
  | SliceThreeStatistics
  | SliceTwoColProducts
  | SliceSlideAnimation
  | SliceCustomSliceDetails
  | SliceCaseStudySectionDetails
  | SliceImageAndContentDetails
  | SliceHeroFullWidthImageDetails
  | SliceTabsFeatureDetails
  | SliceCarouselModuleDetails;

export interface SliceImageGridItem {
  image: ImageField;
}

export interface SliceTextFeatureItem {
  link: { text: string };
  image: {
    alt?: string;
    url: string;
    gatsbyImageData: IGatsbyImageData;
  };
  text: { text: string };
}

export interface SliceIconTextGridItem {
  heading: TextField;
  item_title: TextField & RichTextField;
  image: ImageField;
  link: TextField;
  link_text: TextField;
  type_of_link: string;
  text: HtmlField;
  new_tab: BooleanField;
}

export interface SliceFAQsDetails {
  background_color: string;
  text_color: string;
}

export type SliceFAQsItem = FAQData;

export interface SlicePriorityTipsItem {
  type: 'tip' | 'priority';
  image: ImageField;
  text: TextField;
  priority: PlainTextField;
}

export interface SliceOverlappingItemsItem {
  type: 'overlapping' | 'description';
  image: ImageField;
  icon: ImageField;
  heading: TextField;
  text: TextField;
}

export interface SliceImageTextItem {
  button_text: TextField;
  button_link: TextField;
  button_text_color: PlainTextField;
  button_color: PlainTextField;
}

export interface SliceVmsAtHomeItem {
  id?: string;
  icon: WebResource;
  item: TextField;
  short: TextField;
}

export interface SliceLinkCarouselItem {
  background: TextField;
  heading: TextField;
  subtitle: TextField;
  mobile_subtitle: TextField;
  image: ImageField;
  link_text: TextField;
  link: TextField;
}

export interface SliceIconDescriptionBoxesItem {
  title_text_key?: string;
  main_text_key?: string;
  huge_text_key?: string;
  background_style?: string;
  icon?: ImageField;
  link_text_key?: string;
  link?: WebResource;
}

export interface SliceHowItWorksStepsItem {
  main_text_key?: string;
  info_text_key?: string;
  image?: ImageField;
  video_url?: WebResource;
  video_link_text_key?: string;
  video_preview_image?: ImageField;
}

export interface SlicePositivesAndImageItem {
  main_text_key: string;
}

export interface SliceEndorsmentBoxData {
  name?: { text: string };
  quote?: { text: string };
  title?: { text: string };
  qualification?: { text: string };
  profile_image: ImageDataLike;
}

export interface SliceTimelineItem {
  image: ImageField;
  heading: RichTextField;
  bodycopy?: TextField;
  video_preview_image?: ImageField;
  video_url?: WebResource;
  video_link_text_key?: string;
  link_icon?: boolean;
}

export interface SlicePathogenListItem {}

export interface SliceCaseStudySectionItem {
  research_title: TextField;
  researcher?: TextField;
  research_description?: RichTextField;
  link?: RichTextField;
}

export type PageSliceItem =
  | SliceTimelineItem
  | SlicePathogenListItem
  | SliceTextFeatureItem
  | SliceProductCompareCardsItem
  | SliceInnerButtonSliderItem
  | SliceHeroBannerItem
  | SliceImageTextItem
  | SliceImageGridItem
  | SliceFAQsItem
  | SliceIconTextGridItem
  | SlicePriorityTipsItem
  | SliceOverlappingItemsItem
  | SliceEndorsmentBoxData
  | SliceLinkCarouselItem
  | SliceHeroSliderItem
  | SliceVmsAtHomeItem
  | SliceIconDescriptionBoxesItem
  | SlicePositivesAndImageItem
  | SliceHowItWorksStepsItem
  | SliceImageTextItem
  | SliceBenefitsTableItem
  | SliceHeroSliceItem
  | SliceTextImageCarouselAnimatedItem
  | SliceThreeStatisticsItem
  | SliceTwoColProductsItem
  | SliceSlideAnimationItem
  | SliceCaseStudySectionItem
  | SliceImageAndContentItem
  | SliceHeroFullWidthImageItem
  | SliceTabsFeatureItem
  | SliceCarouselModuleItem;

export enum CartItemType {
  OneOffPurchase = 'oneOffPurchase',
  Subscription = 'subscription',
  GiftCard = 'giftCard',
  Consultation = 'consultation'
}

export enum PathogenCodes {
  lactobacilli = 'lactobacilli',
  anaerobic_bacteria = 'anaerobic_bacteria',
  candida = 'candida',
  mycoplasma_ureaplasma = 'mycoplasma_ureaplasma',
  chlamydia = 'chlamydia',
  gonorrhoea = 'gonorrhoea',
  trichomoniasis = 'trichomoniasis',
  herpes_with_types = 'herpes_with_types',
  hpv_screening = 'hpv_screening'
}

export interface PathogenDataItem {
  code: PathogenCodes;
  title: string;
  shortInfo: string;
  color?: string;
  background?: string;
  image?: string;
  info?: string;
  symptoms?: string[];
  onlySTI?: boolean;
  onlyHPV?: boolean;
  comingSoon?: boolean;
  comingSoonCountryFilter?: string;
}

export interface TrackingGoogleProduct {
  id: string;
  name: string;
  price: string;
  brand: string;
  category: string;
  quantity: number;
  subscriptionPlan?: string;
  [key: string]: number | string | undefined;
}

export interface NavigationSection {
  title?: string;
  items: NavigationItem[];
  shopAllLink?: string;
}

export interface NavigationItem {
  title: string;
  link: string;
  show_in_countries?: string;
  on_sale?: boolean;
  is_new?: boolean;
  text_color?: string;
  icon_svg?: {
    url: string;
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

export interface NavigationPreview {
  title: string;
  link: string;
  image: string;
  imageAlt?: string;
  badgeText?: string;
  badgeTextColor?: string;
  badgeBackground?: string;
}

export interface ProcessedNavigationData {
  periodCare: NavigationSection;
  vaginalHealth: NavigationSection;
  accessories: NavigationSection;
  aboutUs: NavigationSection;
  discover: NavigationSection;
  footer: NavigationSection;
  policies: NavigationSection;
  retailers: NavigationSection;
  productCategories: NavigationPreview[];
  vitalsArticleUids: string[];
}

export enum ButtonTypes {
  AddToCart,
  Default,
  ChooseABox
}

export interface StickyBannerData {
  productCode: ProductCode;
  title: string;
  subtitle: string;
  textColor: string;
  buttonText?: string;
  buttonLink?: string;
  icon?: ImageField;
}

export interface ProductHeaderVideo {
  thumbnail: ImageField;
  video: VideoField;
}

export interface ProductHeaderImage {
  order: NumberField;
  product_image: ImageField;
}

export interface ProductHeaderIcon {
  icon: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
    url: string;
  };
  icon_text: { text: string };
  icon_description: { text: string };
}

export interface ProductHeaderOption {
  label: { text: string };
  value: { text: string };
}

export interface ProductHeaderLink {
  link: { text: string };
  text: { text: string };
}

export interface HeaderData {
  topSubtitle: string;
  heading: string;
  subtitle: string;
  text: string;
  subtext: string;
  trustpilot?: boolean;
  buttonType: string;
  shopDisabled: boolean;
  learnMoreLink: {
    text: string;
  };
  verticalIcons: boolean;
  productCode: ProductCodeKey;
  productOptions: ProductRawOption[];
  configurationTitle?: string;
  paymentOptionsTitle?: string;
  addonOptionsTitle?: string;
  addonProducts?: string;
  preselectedTamponBoxes?: boolean;
  textColor: string;
  background: string;
  klaviyoId: string;
  feedback: {
    feedback_image: ImageField;
    feedback_text: TextField;
  }[];
  videos: ProductHeaderVideo[];
  icons: ProductHeaderIcon[];
  images: ProductHeaderImage[];
  options?: ProductHeaderOption[];
  links: ProductHeaderLink[];
  promoRibbon: PromoRibbon;
  payment_options_header: TextField;
  product_header_faqs: FAQData[];
  is_using_new_design_system?: boolean;
  upsell_products_title?: string | null;
  upsell_products?: UpsellProductData[];
  fireworkIntegrationType: string;
  fireworkPlaylistId: string;
  fireworkWidgetSize: string;
}

export interface UpsellProductData {
  upsell_product_code: string | null;
}

export interface SimpleOption {
  label: string;
  value: string;
}

export interface ProductShoppingContextProps {
  selectedBox?: SelectedBox;
  productHeaderData: HeaderData;
  stickyBanner?: StickyBannerData;
  filteredLinks: ProductHeaderLink[];
  hasFeedbackView: boolean;
  topSubtitleText: string | undefined;
  paymentOptionsHeader: string;
  headingText: string;
  bodyText: string;
  subtextText: string;
  orderedSlides: (ProductHeaderVideo | ProductHeaderImage)[];
  loaded: boolean;
  price: number;
  boxState?: BoxState;
  padsBoxState?: PadsBoxState;
  padsBoxStackState?: PadsBoxStackState;
  showQuantity: boolean;
  subscriptionId: string;
  configVariant: string;
  layout: string;
  subscriptions: SubscriptionPlan[];
  isTracked: boolean;
  prices: Prices;
  paymentOptions: Array<SimpleOption>;
  paymentOptionSelected?: SimpleOption | null;
  isUsingNewDesignSystem: boolean;
  isDiagnosticTamponProduct: boolean;
  showNurseConsultation: boolean;
  preselectedTamponBoxes: boolean;
  productId: string;
  scrollHeight?: number;
  hasProductOptions: boolean;
  productOptionsInfo: ProductOption[];
  productAddonsInfo: ProductAddon[];
  selectedOptionCartItem: CartItem | null;
  selectedAddonCartItems: CartItem[];
  selectedCartItems: CartItem[];
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setBoxState: React.Dispatch<React.SetStateAction<BoxState>>;
  setPadsBoxState: React.Dispatch<React.SetStateAction<PadsBoxState>>;
  setPadsBoxStackState: React.Dispatch<React.SetStateAction<PadsBoxStackState>>;
  setShowQuantity: React.Dispatch<React.SetStateAction<boolean>>;
  setSubscriptionId: React.Dispatch<React.SetStateAction<string>>;
  setConfigVariant: React.Dispatch<React.SetStateAction<string>>;
  setLayout: React.Dispatch<React.SetStateAction<string>>;
  setSubscriptions: React.Dispatch<React.SetStateAction<SubscriptionPlan[]>>;
  setIsTracked: React.Dispatch<React.SetStateAction<boolean>>;
  setPrices: React.Dispatch<React.SetStateAction<Prices>>;
  setPaymentOptionSelected: React.Dispatch<React.SetStateAction<SimpleOption>>;
  setProductId: React.Dispatch<React.SetStateAction<string>>;
  setScrollHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedOptionCartItem: React.Dispatch<React.SetStateAction<CartItem | null>>;
  setSelectedAddonCartItems: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

export interface PromoRibbon {
  icon: ImageField;
  text: string;
  textColor: string | null;
  borderColor: string | null;
  backgroundColor: string | null;
}

export enum TrustpilotTemplateId {
  Basic = '5406e65db0d04a09e042d5fc',
  ReviewsCarousel = '53aa8912dec7e10d38f59f36',
  Slider = '54ad5defc6454f065c28af8b'
}

export interface SiteBannerData {
  priority?: number;
  promotion_id: {
    text?: string;
  };
  promotion_name: {
    text?: string;
  };
  banner_text: {
    richText: [RTNode];
  };
  banner_link: {
    text?: string;
  };
  new_window: boolean;
  background_color: string;
  text_color: string;
  highlight_background_color?: string;
  highlight_text_color?: string;
  counter_background_color?: string;
  counter_text_color?: string;
  counter_date?: string;
  logged_in: boolean;
  trustpilot: boolean;
  page_path_includes: {
    text?: string;
  };
  page_path_excludes: {
    text?: string;
  };
  country_code: {
    text?: string;
  };
  google_analytics_id: {
    text?: string;
  };
}

export type QuestionOption = {
  value: string;
  label: string;
  description?: string;
  metadata?: string;
  tooltip?: string;
  order?: number;
};

export type QuestionOptionAnswer = {
  value: string;
  label: string;
  metadata?: string;
};

export interface ResultParameter {
  value: number;
  reference?: Array<number | string>;
  inRange?: boolean;
  percentage?: number;
  iconSrc?: string;
  isVmsSpecific?: boolean;
  isStiSpecific?: boolean;
  isHpvSpecific?: boolean;
  parameterName?: string;
  whatIsText?: string;
  rangeSkip?: boolean;
  detectionStatus?: DetectionStatus;
}

export enum ResultParameterType {
  SAMPLE_INTAKE_CONTROL = 'sampleIntakeControl',
  TOTAL_BACTERIAL_MASS = 'totalBacterialMass',
  LACTOBACILLUS = 'lactobacillus',
  CANDIDA = 'candida',
  ANAEROBIC_MICROORGANISMS = 'anaerobicMicroorganisms',
  MYCOPLASMA_UREAPLASMA = 'mycoplasmaUreaplasma',
  MYCOPLASMA_HOMINIS = 'mycoplasmaHominis',
  UREAPLASMA = 'ureaplasma',
  CHLAMYDIA = 'chlamydia',
  GONORRHOEA = 'gonorrhoea',
  TRICHOMONIASIS = 'trichomoniasis',
  MYCOPLASMA_GENITALIUM = 'mycoplasmaGenitalium',
  HPV16 = 'hpv16',
  HPV18 = 'hpv18',
  HPV_OTHER = 'hpvOther'
}

export interface ResultParameters {
  [ResultParameterType.SAMPLE_INTAKE_CONTROL]?: ResultParameter;
  [ResultParameterType.TOTAL_BACTERIAL_MASS]?: ResultParameter;
  [ResultParameterType.LACTOBACILLUS]?: ResultParameter;
  [ResultParameterType.CANDIDA]?: ResultParameter;
  [ResultParameterType.ANAEROBIC_MICROORGANISMS]?: ResultParameter;
  [ResultParameterType.MYCOPLASMA_UREAPLASMA]?: ResultParameter;
  [ResultParameterType.MYCOPLASMA_HOMINIS]?: ResultParameter;
  [ResultParameterType.UREAPLASMA]?: ResultParameter;
  [ResultParameterType.CHLAMYDIA]?: ResultParameter;
  [ResultParameterType.GONORRHOEA]?: ResultParameter;
  [ResultParameterType.TRICHOMONIASIS]?: ResultParameter;
  [ResultParameterType.MYCOPLASMA_GENITALIUM]?: ResultParameter;
  [ResultParameterType.HPV16]?: ResultParameter;
  [ResultParameterType.HPV18]?: ResultParameter;
  [ResultParameterType.HPV_OTHER]?: ResultParameter;
}

export interface QuestionnaireDataStep {
  order: number;
  title: string;
  buttonText: string;
  description: string;
  questions: Question[];
}

export interface ScreeningHowToHelpData {
  title: string;
  articleIds?: string[];
  hasNextStepsButton?: boolean;
  hasNotifyPartner?: boolean;
  sections: ScreeningHowToHelpSections[];
}

export enum TreatmentTypes {
  Thrush = 'Thrush treatment',
  Bv = 'BV treatment',
  Ureaplasma = 'Ureaplasma treatment',
  Chlamydia = 'Chlamydia treatment',
  Trichomoniasis = 'Trichomoniasis treatment',
  MycoplasmaGenitalium = 'M. genitalium treatment',
  MycoplasmaHominis = 'M. hominis treatment' // Currently only in branded products
}

export interface TreatmentTypeData {
  id: TreatmentTypes;
  name: string;
  icon: string;
  pathogenId?: ResultParameterType;
}

export interface BrandSpecialist {
  name: string;
  picture: string;
  shortDescription: string;
  url: string;
  isPrepaid: boolean;
  buttonText?: string;
}

export interface ScreeningHowToHelpSections {
  hasFindFreeClinicNearby?: boolean;
  hasGetTreatmentFromLocalClinic?: boolean;
  hasProviotics?: boolean;
  hasSpecialistWillCall?: boolean;
  heading?: string;
  headingIcon?: string;
  description?: string;
  specialistNames?: string[];
  productCodes?: ProductCode[];
  treatmentTypeIds?: TreatmentTypes[];
  brandedProduct?: {
    title: string;
    subtitle?: string;
    link: string;
    background?: string;
    border?: string;
    icon: React.ReactNode;
  };
  brandedSpecialists?: BrandSpecialist[] | null;
  cardTitle?: string;
}

export interface QuestionnaireData {
  steps: QuestionnaireDataStep[];
}

export interface ParameterSymptomData {
  questionnairePositiveAnswerNames: string[];
  questionnaireNegativeAnswerNames: string[];
}

export type CategorisedSymptomNames = {
  vaginalDischarge: string[];
  vaginalSymptoms: string[];
};

export interface ProcessedResultData {
  type: ResultType;
  typeName: string;
  icon: string;
  iconWithCircle: string;
  results: ResultParameters;
  hasLactobacillus: boolean;
  hasPathogenic: boolean;
  hasCandida: boolean;
  hasMycoplasma: boolean;
  hasMycoplasmaHominis: boolean;
  hasAnaerobicMicroorganisms: boolean;
  hasUreaplasma: boolean;
  hasChlamydia: boolean;
  hasGonorrhoea: boolean;
  hasTrichomoniasis: boolean;
  hasMycoplasmaGenitalium: boolean;
  hasHpv16: boolean;
  hasHpv18: boolean;
  hasHpvOther: boolean;
  hasVms: boolean;
  isVmsPositive: boolean;
  isVmsInconclusive: string;
  hasSti: boolean;
  isStiPositive: boolean;
  isStiInconclusive: string;
  hasHpv: boolean;
  isHpvPositive: boolean;
  isHpvInconclusive: string;
  hpvScreenTakenLast3Years: boolean;
  symptoms: CategorisedSymptomNames;
  conditions: {
    BV: boolean;
    thrush: boolean;
    pregnant: boolean;
    other: boolean;
  };
}

export enum ResultEvaluationDetection {
  Inconclusive = 'inconclusive',
  NotDetected = 'not-detected',
  InAbundance = 'in-abundance',
  Reduced = 'reduced',
  Depleted = 'depleted',
  Detected = 'detected',
  DetectedNormal = 'detected-normal',
  DetectedAbnormal = 'detected-abnormal'
}

export enum MicrobiomeBalance {
  WellBalanced = 'well-balanced',
  ModeratelyDisbalancedAnaerobic = 'moderately-disbalanced-anaerobic',
  ModeratelyDisbalancedOther = 'moderately-disbalanced-other',
  DisbalancedAnaerobic = 'disbalanced-anaerobic',
  DisbalancedOther = 'disbalanced-other',
  Undetermined = 'undetermined'
}

export const fbAppId = '521270401588372';
export interface ApplePayShippingContact {
  givenName: string;
  familyName: string;
  addressLines: string[];
  locality: string;
  administrativeArea: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
}

export interface GooglePayShippingContact {
  name: string;
  postalCode: string;
  countryCode: string;
  phoneNumber?: string;
  address1: string;
  address2: string;
  address3: string;
  locality: string;
  administrativeArea: string;
  sortingCode: string;
}

export interface Credit {
  count: number;
  variant: ProductCodeKey;
  latestSubscriptionId?: string;
}

export interface Treatment {
  id?: string;
  resultId?: string;
  pharmacyOrderId?: string;
  status?: TreatmentStatus;
  rejectedReason?: string;
  medicationId?: MedicationProductCodeKey | ProductCode.PPCTamponBox;
  medicationInstructions?: string;
  createdDate?: Date;
  reviewedDate?: Date;
  updatedDate?: Date;
  activeFromDate?: Date;
  activeToDate?: Date;
}

export interface Promotion {
  promotion_id?: string;
  name?: string;
  creative?: string;
  position?: string;
  products?: any;
}

export interface Locale {
  path: string;
  label: string;
  locale: string;
  default?: boolean;
}

export enum Medication {
  CLOTRIMAZOLE = 'Clotrimazole',
  FLUCONAZOLE = 'Fluconazole',
  BORICACID = 'Boric acid',
  METRONIDAZOLE = 'Metronidazole',
  CLINDAMYCIN = 'Clindamycin',
  RELACTAGEL = 'Relactagel',
  CHLAMYDIA = 'Chlamydia treatment',
  TRICHOMONIASIS = 'Trichomoniasis treatment',
  MYCOPLASMA_GENITALIUM = 'M. genitalium treatment',
  BV = 'BV treatment',
  UREAPLASMA = 'Ureaplasma treatment',
  THRUSH = 'Thrush treatment'
}

export enum MedicationType {
  Yeast,
  BV,
  STI
}

export interface VariantItem {
  variant: string;
  count: number;
}

export interface VariantsConfiguration {
  [key: string]: VariantItem[];
}

export interface ProductConfiguration {
  configuration: VariantItem[];
  createdBy: string;
  createdTimestamp: number;
  updatedTimestamp: number;
  variant: string;
}

export interface SimpleLink {
  text: string;
  to: string;
}

export interface WhatsNextSlice {
  primary: {
    wn_image: WebResource;
    wn_title: TextField;
    wn_text: TextField;
  };
}

export interface AlgoliaHighlightResultField {
  value: string;
  matchLevel: string;
  matchedWords: string[];
  fullyHighlighted?: boolean;
}

export type BaseHit = Record<string, unknown>;

export interface AlgoliaHit extends BaseHit {
  category: 'product' | 'article' | 'landing' | 'product_category' | 'article_category' | 'press';
  content?: string;
  title?: string;
  image?: string;
  description?: string;
  meta_description?: string;
  meta_image?: string;
  meta_title?: string;
  lang: string;
  objectID: string;
  id?: string;
  path: string;
}

export enum DigitalServiceType {
  VMS = 'vms',
  PPC = 'ppc'
}

export interface LinkTranslatedProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, React.RefAttributes<HTMLAnchorElement> {
  to?: string;
  href?: undefined;
  language?: string;
  targetBlank?: boolean;
  preventDefault?: boolean;
  hrefCountry?: CountryCode;
  [key: string]: any;
}

export interface StyledButtonProps {
  $size?: 'small' | 'normal' | 'large' | 'small-round';
  $buttonTextColor?: string;
  $backgroundColor?: string;
  $borderRadius?: string;
  $isInline?: boolean;
}

export interface SliceSlideAnimation {
  intro_heading: TextField;
  intro_subheading: PlainTextField;
  intro_image: ImageField;
  anim_image_one: ImageField;
  anim_image_two: ImageField;
}

export interface SliceCustomSliceDetails {
  type: CustomSliceType;
}

export enum CustomSliceType {
  PadsTechSpecifics = 'PadsTechSpecifics'
}

export interface SliceSlideAnimationItem {
  textbox_title: TextField;
  big_number: PlainTextField;
  textbox_description: TextField;
}

export interface SliceTwoColProducts {
  heading: TextField;
  feature_icons: ImageField;
}

export interface SliceTwoColProductsItem {
  aux_image: ImageField;
  overline: PlainTextField;
  heading: TextField;
  image: ImageField;
  features: HtmlField;
}

export interface SliceThreeStatistics {
  heading: TextField;
}
export interface SliceThreeStatisticsItem {
  image: ImageField;
  big_number: PlainTextField;
  stat_description: TextField;
}

export interface SliceTextImageCarouselAnimated {
  overline: PlainTextField;
  heading: TextField;
  intro_description: HtmlField;
  intro_image: ImageField;
  did_you_know_text: PlainTextField;
}
export interface SliceTextImageCarouselAnimatedItem {
  overline: PlainTextField;
  heading: TextField;
  slide_description: HtmlField;
  image: ImageField;
}

export interface SliceGridDetails {
  overline: string;
  heading: TextField;
  subheading: TextField;
  buttonlink: WebResource;
  buttontext: TextField;
  background_text: string;
  variant: number;
}

export interface SliceGridItem {
  image: ImageField;
  heading: RichTextField;
  mobile_heading: RichTextField;
  bodycopy: TextField;
  mobile_bodycopy: TextField;
  supbodycopy: TextField;
  learn_more_url: string;
}

export interface SliceFlexibleGridDetails {
  overline: PlainTextField;
  heading: RichTextField;
  background_text: PlainTextField;
  include_overlay: boolean;
}

export interface SliceFlexibleGridItem {
  width: PlainTextField;
  image: ImageField;
  heading: RichTextField;
  bodycopy: TextField;
  textcolor: string;
  buttontext: PlainTextField;
  button_url: PlainTextField;
}

export interface SliceBannerGridDetails {
  background_text: PlainTextField;
}

export interface SliceBannerGridItem {
  background_text: string;
  background_image: ImageField;
  image: ImageField;
  heading: RichTextField;
  overline: PlainTextField;
  textcolor: string;
  buttontext: PlainTextField;
  button_url: string;
}

export interface SliceLinkTabsDetails {
  overline: PlainTextField;
  heading: RichTextField;
  background_text: PlainTextField;
}

export interface SliceLinkTabsItem {
  image: ImageField;
  title: RichTextField;
  button_url: PlainTextField;
}

export interface SliceInnerButtonSliderDetails {
  background_text: string;
}

export interface SliceInnerButtonSliderItem {
  overline: string;
  image: ImageField;
  heading: RichTextField;
  bodycopy: TextField;
}

export interface SliceProductCompareCardsDetails {
  heading: RichTextField;
  products_to_include: PlainTextField;
  screening_type: ScreeningType;
}

export interface SliceProductCompareCardsItem {}

export interface SliceHeroBannerDetails {
  background_text: string;
  overline: string;
  button_url: string;
  button_label: string;
  image: ImageField;
  aux_image: ImageField;
  heading: RichTextField;
  bodycopy: TextField;
  breakpoint_px: number;
  no_mobile_image: boolean;
}

export interface SliceHeroBannerItem {}

export interface SliceImageAndContentDetails {
  overline?: KeyTextField;
  heading: RichTextField;
  subheading: RichTextField;
  centered_text: boolean;
  image_full_background: boolean;
  button_url: KeyTextField;
  button_text_key: KeyTextField;
  aux_image: ImageField;
  image: ImageField;
  image_mobile: ImageField;
  notice: KeyTextField;
  reverse_order: boolean;
}

export interface SliceImageAndContentItem {
  icon: ImageField;
  icon_text: KeyTextField;
}

export interface SliceHeroFullWidthImageDetails {
  heading: RichTextField;
  subheading: RichTextField;
  show_starting_at: BooleanField;
  fsa_hsa_eligible: BooleanField;
  button_url: KeyTextField;
  button_text_key: KeyTextField;
  dark_text: BooleanField;
  dark_button: BooleanField;
  align_top_mobile: BooleanField;
  background_image: ImageField;
  background_image_mobile: ImageField;
  product_code: KeyTextField;
}

export interface SliceHeroFullWidthImageItem {}

export interface SliceDonationHeroDetails {
  overline: PlainTextField;
  total_amount: PlainTextField;
  target_amount: PlainTextField;
  bodytext: RichTextField;
  button_url: PlainTextField;
  button_text_key: PlainTextField;
  dark_text: BooleanField;
  dark_button: BooleanField;
  align_top_mobile: BooleanField;
  background_image: ImageField;
  background_image_mobile: ImageField;
}

export interface SliceTabsFeatureDetails {
  overline?: KeyTextField;
  heading: RichTextField;
  background_color: KeyTextField;
  centered_text: boolean;
}

export interface SliceTabsFeatureItem {
  icon: ImageField;
  icon_text: KeyTextField;
  image: ImageField;
  image_mobile: ImageField;
  item_heading: RichTextField;
  item_subheading: RichTextField;
  button_url: KeyTextField;
  button_text_key: KeyTextField;
  item_centered_text: boolean;
  item_reverse_order: boolean;
  item_reverse_order_mobile: boolean;
  background_full_width: boolean;
  background_color: KeyTextField;
  background_image: ImageField;
}

export interface SliceCarouselModuleDetails {
  background_color: KeyTextField;
}

export interface SliceCarouselModuleItem {
  overline: KeyTextField;
  heading: RichTextField;
  subheading: RichTextField;
  button_url: KeyTextField;
  button_text_key: KeyTextField;
  image: ImageField;
}

export interface SliceHeroSlice {
  hero_slice_heading: HtmlField;
  hero_slice_image: ImageField;
}
export interface SliceHeroSliceItem {
  feature_icon: ImageField;
  feature_text: PlainTextField;
}

export interface SliceHeadingMessage {
  heading_text: HtmlField;
  background_color: PlainTextField;
}

export interface SliceBenefitsTable {
  heading: TextField;
}

export interface SliceBenefitsTableItem {
  image: ImageField;
  description: PlainTextField;
}
export interface ProductBuyOption {
  oneOffSku?: string;
  subscriptionSku?: string;
  subscriptionPlan?: string;
  label: string;
  title: string;
  icon: JSX.Element;
  subscriptionCount: number;
  isAddon?: boolean;
  oneOffCount: number;
  variants: VariantsConfiguration;
  includes: string[];
  optionInfo?: ProductBuyOptionInfo[];
}

export interface ProductRawOption {
  one_off_sku: { text: string };
  subscription_sku: { text: string };
  subscription_plan: { text: string };
  label: { text: string };
  title?: { text: string };
  subscription_includes?: { text: string };
  addon?: boolean;
  option_info_json?: { text: string };
}

export interface ProductOption {
  oneOffSku: string | null;
  subscriptionSku: string | null;
  subscriptionPlan: string | null;
  label: string;
  title: string;
  includes: string;
  optionInfo: string | null;
}

export interface ProductAddon {
  oneOffSku: string | null;
  subscriptionSku: string | null;
  subscriptionPlan: string | null;
  label: string;
  title: string;
  includes: string;
}

export interface ProductBuyOptionInfo {
  type?: string;
  text?: string;
  items?: string[];
}

export interface DSShoppingModuleSelectOption {}

export interface DSImageCarouselSlide {
  product_image?: {
    gatsbyImageData: IGatsbyImageData;
    alt?: string;
    url: string;
  };
  video?: {
    url: string;
    alt?: string;
  };
  thumbnail?: {
    gatsbyImageData: IGatsbyImageData;
    alt?: string;
  };
}

export interface DSImageCarouselProps {
  slides: DSImageCarouselSlide[];
}

export interface FAQData {
  question: TextField;
  answer: RichTextField;
  show_in_countries?: TextField;
}

export interface ProcessedFAQData {
  questionText: string;
  answerText: RichTextBlock[];
  answerTextString?: string;
}

export interface ResearchStudy {
  title: string;
  url: string | WebResource;
}

export interface WhatResearchSaysData {
  title: string;
  icon: string;
  descriptionText: string;
  studies: ResearchStudy[];
}

export interface AccordionData {
  title: string;
  content: string | RichTextField;
}

export type BadgeType = 'success' | 'warning' | 'positive' | 'negative' | 'neutral' | 'inconclusive' | 'recommended';

export type BadgeSizeType = 'small' | 'plus' | 'inline-block';

export interface TextWithButtonData {
  onClick: () => void;
  productCode: ProductCode;
  buttonText: string;
  type: ButtonTypes;
  backgroundColor?: string;
  description?: string;
}

export interface PriceObject {
  amount: string;
  currency: string;
}

export type PriceSimple = string;

export type PriceComplex = PriceSimple | PriceObject[];

export interface DropdownOptions {
  value: string;
  label: string;
}

export enum FieldType {
  Text,
  Email,
  Textarea,
  Select,
  Phone,
  Date,
  Checkbox,
  MultiCheckbox
}

export interface FormField {
  id: string;
  type: FieldType;
  label: string;
  description?: string;
  placeholder: string;
  options?: DropdownOptions[];
}

export type InfoBarType = 'error' | 'success' | 'warning' | 'time' | 'processing' | 'order' | '';

export enum ScreeningReportSection {
  Results = 'results',
  NextSteps = 'next-steps',
  Resources = 'resources',
  Pathogen = 'pathogen',
  MoreInfo = 'more-info',
  HowToDo = 'how-to-do',
  Resource = 'resource'
}

export enum StepType {
  Specialists = 'specialists',
  SpecialistsWisp = 'specialistsWisp',
  TreatmentOptions = 'treatmentOptions',
  Retest = 'retest',
  RetestWisp = 'retestWisp',
  PreventInfections = 'preventInfections',
  PreventionWisp = 'preventionWisp'
}

export interface ScreeningReportTab {
  id: ScreeningReportSection;
  title?: string;
  icon?: JSX.Element;
}

export interface ChangeCurrentTabOptions {
  sectionId: ScreeningReportSection;
  paramId?: ResultParameterType;
  resourceId?: string;
  scrollSectionId?: string;
}

export interface ScreeningReportContextProps {
  result?: MedicalResult | null;
  resultData?: ProcessedResultData | null;
  insight?: MedicalInsight | null;
  insightItems?: InsightItem[];
  screeningId?: string | null;
  healthProfileLoaded: boolean;
  parameterData?: ResultParameter | null;
  parameterId?: ResultParameterType | null;
  resourceId?: string | null;
  currentTab: ScreeningReportSection;
  visibleTabs: ScreeningReportTab[];
  questionnaireData: QuestionnaireData | null;
  resultEvaluation: ResultEvaluationDetection | null;
  daysSinceSample: number;
  isResultFine: boolean | null;
  showTreatmentOptions: boolean | null;
  symptomsRequireAttention: boolean | null;
  sampleRequiresRetest: boolean | null;
  resultCountry: CountryCode;
  resultCurrency: CurrencyObj;
  changeCurrentTab: (changeCurrentTabProps: ChangeCurrentTabOptions) => void;
}

export interface PricingCompareItem {
  screeningType?: ScreeningType;
  productCode: ProductCode;
  title: string;
  isBestValue?: boolean;
  isMostPopular?: boolean;
  testFor: string[];
}

export const ONE_MONTH_INTERVAL_LENGTH = 1;
export const THREE_MONTH_INTERVAL_LENGTH = 3;

export enum TamponBoxType {
  Tampons12 = 'Tampons12',
  Tampons18 = 'Tampons18',
  TamponsPads12 = 'TamponsPads12',
  TamponsPads18 = 'TamponsPads18'
}

export interface CheckoutContextProps {
  shippingAddress: ShippingAddress | null;
  setShippingAddress: React.Dispatch<React.SetStateAction<ShippingAddress | null>>;
}

export enum FireworkWidgetType {
  Player = 'player',
  EmbedFeed = 'embedFeed',
  Storyblock = 'storyblock'
}

export type LocaleContextState = I18NextContext;

export interface SelectOption {
  value: string;
  label: string | JSX.Element;
}

export enum CouponDiscountType {
  Percent = 'percent',
  Fixed = 'fixed'
}

export enum CouponState {
  Redeemable = 'redeemable',
  NotRedeemable = 'not_redeemable'
}

export type CountrySelectStyleVariant = 'default' | 'footer' | 'dashboard-footer';

export interface DidYouKnowProps {
  text: string | string[];
  headingText?: string;
  centered?: boolean;
  expanded?: boolean;
  expandable?: boolean;
  compact?: boolean;
  background?: string;
  borderRadius?: string;
  borderColor?: string;
  icon?: React.ReactNode | 'greeting' | 'did-you-know' | 'info' | 'warning' | 'error' | 'hourglass' | 'nurse' | 'order';
  fullWidth?: boolean;
  localisationCounty?: CountryCode;
}

export interface CardSmallV2Props {
  title: string;
  size?: 'normal' | 'large';
  subtitle?: string;
  linkTo?: string;
  targetBlank?: boolean;
  showiconRight?: boolean;
  iconRight?: React.ReactNode;
  iconRightColor?: string;
  iconRightText?: string;
  background?: string;
  border?: string;
  onLinkClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  image?: string | React.ReactNode;
  imageBackground?: string;
  badgeText?: string;
  badgeType?: BadgeType;
  inlineBadgeText?: string;
  inlineBadgeType?: BadgeType;
  isCompleted?: boolean;
}
